<template>
  <b-overlay :show="isLoading" rounded="sm">
    <div class="row mt-2">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="text-center">
              <h1 class="text-primary">Detail Discount</h1>
            </div>
            <br />
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      General
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <label class="col-sm-2">Discount Name</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item?.name }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Description</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item?.description }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Campaign</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ itemCampaign?.name }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Valid From</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ formatDate(item?.valid_from) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Valid To</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ formatDate(item?.valid_to) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Sales Recognition</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getSalesRecognition(item?.sales_recognition) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Partner Participation</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getPartnerParticipant(item?.partner_participation) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Vendor Participation</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getPartnerParticipant(item?.partner_participation) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Time Status</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div
                          v-if="formatDate(item?.valid_to) >= datenow"
                          class="badge badge-info"
                        >
                          Promo Currently Active
                        </div>
                        <div
                          v-else-if="formatDate(item?.valid_to) <= datenow"
                          class="badge badge-danger"
                        >
                          Promo Already Expired
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Status Active</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        <div v-if="item?.is_active" class="badge badge-info">
                          active
                        </div>
                        <div v-else class="badge badge-danger">
                          inactive
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Detail Discount
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <label class="col-sm-2">Discount Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getDiscountType(item?.discount_type) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Discount Rule</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getDiscountRule(item?.discount_rule) }}
                      </div>
                    </div>
                    <!-- <div class="form-group row">
                      <label class="col-sm-2">Trial Promotions</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getTimeTrial(item?.trial_time) }}
                      </div>
                    </div> -->
                    <div class="form-group row">
                      <label class="col-sm-2">Discount Point</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ item?.discount_point }}
                        {{ item?.discount_point > 1 ? 'Points' : 'Point' }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Discount IDR</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{
                          getDiscountIdr(
                            item?.discount_idr,
                            item?.discount_rule,
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Discount USD</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{
                          getDiscountUsd(
                            item?.discount_usd,
                            item?.discount_rule,
                          )
                        }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Min. USD Order Price</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ numberFormatUsd(item?.min_usd_order_price) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Max. USD Order Price</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ numberFormatUsd(item?.max_usd_order_price) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Min. IDR Order Price</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ numberFormatIdr(item?.min_idr_order_price) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Max. IDR Order Price</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ numberFormatIdr(item?.max_idr_order_price) }}
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-sm-2">Platform</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ itemPlatform }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Detail BIN Code
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <b-table
                          id="table-transition"
                          show-empty
                          :fields="headBinCode"
                          hover
                          responsive="xl"
                          :items="item?.bin_codes"
                          sort-icon-left
                        >
                          <template #cell(No)="row">
                            {{ row.index + 1 }}.
                          </template>
                          <template #cell(bincode)="row">
                            {{ getBinCode(row.item) }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Detail Discount Payment Gateway
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <div class="col-sm-6">
                        <b-table
                          id="table-transition"
                          show-empty
                          :fields="headPaymentGateway"
                          hover
                          responsive="xl"
                          :items="paymentgateway"
                          sort-icon-left
                        >
                          <template #cell(No)="row">
                            {{ row.index + 1 }}.
                          </template>
                          <template #cell(payment)="row">
                            {{ row.item.name }}
                          </template>
                        </b-table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="card border-0">
                  <div class="card-header bg-primary">
                    <h5>
                      Detail Discount Offer
                    </h5>
                  </div>
                  <div class="card-body">
                    <div class="form-group row">
                      <label class="col-sm-2">Offer Type</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getOfferType(item?.predefined_group) }}
                      </div>
                    </div>
                    <div
                      class="form-group row"
                      v-if="item?.predefined_group != null"
                    >
                      <label class="col-sm-2">General Offer</label>
                      <label class="col-sm-1">:</label>
                      <div class="col-sm-9">
                        {{ getGeneralOffer(item?.predefined_group) }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="form-group row">
                        <div class="col-sm-6">
                          <b-table
                            id="table-transition"
                            show-empty
                            :fields="headOfferID"
                            hover
                            responsive="xl"
                            :items="offerID"
                            sort-icon-left
                          >
                            <template #cell(No)="row">
                              {{ row.index + 1 }}.
                            </template>
                            <template #cell(offerID)="row">
                              {{ row.item }}
                            </template>
                          </b-table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import moment_timezone from 'moment-timezone';
import constant from '../../store/constant';
import moment from 'moment';
export default {
  name: 'DiscountDetail',
  data() {
    return {
      datenow: moment().format('YYYY-MM-DD'),
      itemPlatform: null,
      headOrderline: [
        { key: 'No', label: 'No' },
        { key: 'id', label: 'Orderline ID' },
        { key: 'item_name', label: 'Item Name' },
        { key: 'qty', label: 'Qty' },
        { key: 'price', label: 'Price' },
        { key: 'discount', label: 'Discount' },
        { key: 'final_price', label: 'Final Price' },
      ],
      headBinCode: [
        { key: 'No', label: 'No', thClass: 'bg-secondary' },
        { key: 'bincode', label: 'BIN Code', thClass: 'bg-secondary' },
      ],
      binCode: [],
      headOfferID: [
        { key: 'No', label: 'No', thClass: 'bg-secondary' },
        { key: 'offerID', label: 'Offer ID', thClass: 'bg-secondary' },
      ],
      offerID: [],
      headPaymentGateway: [
        { key: 'No', label: 'No', thClass: 'bg-secondary' },
        { key: 'payment', label: 'Payment Gateway', thClass: 'bg-secondary' },
      ],
      paymentgateway: [],
    };
  },
  computed: {
    ...mapState({
      isError: (state) => state.discounts.isError,
      isLoading: (state) => state.discounts.isLoading,
      item: (state) => state.discounts.item,
      itemCampaign: (state) => state.banners.item,
    }),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title =
          to.meta.title ||
          'Detail Discount | Content Management System Ebooks Gramedia.com';
      },
    },
    item: function() {
      if (!this.item)
        this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
      this.getCampaign(this.item.campaign_id);
      this.getPlatform(this.item.id);
      this.getOfferID(this.item.id);
      this.getPaymentGateway(this.item.id);
    },
    isError: function() {
      if (!this.isError) return;
      this.alertMsg('Ops. Something Went Wrong. Please Try Again', 'error');
    },
  },
  created: function() {
    const discountID = this.$route.params.id;
    this.fetchDiscountsDetailByid(discountID);
  },

  methods: {
    ...mapActions('discounts', [
      'fetchDiscountsDetailByid',
      'fetchDiscountsPlatformsid',
      'fetchDiscountsOffersid',
      'fetchDiscountsPaymentsid',
    ]),
    ...mapActions('banners', ['fetchCampaignById']),

    formatDate(tgl) {
      return moment_timezone
        .tz(tgl, 'Asia/Jakarta')
        .format('YYYY-MM-DD HH:mm:ss');
    },

    getSalesRecognition(id) {
      if (id == 1) {
        return 'Recognize as Normal Sales';
      }
      return 'Recognize as other incomes';
    },

    getPartnerParticipant(id) {
      const partner = Object.keys(constant.PARTNER_PARTICIPATION).find(
        (key) => constant.PARTNER_PARTICIPATION[key] === id,
      );
      return partner;
    },

    getCampaign(id) {
      this.fetchCampaignById(id);
    },

    getDiscountType(id) {
      if (id == 1) {
        return 'Discount Offer';
      }
      return 'Discount Code';
    },

    getDiscountRule(id) {
      const discountRule = Object.keys(constant.OFFER_DISCOUNT_RULE).find(
        (key) => constant.OFFER_DISCOUNT_RULE[key] === id,
      );
      return discountRule;
    },

    getTimeTrial(id) {
      if (id) {
        const timeTrial = Object.keys(constant.TRIALS).find(
          (key) => constant.TRIALS[key] === id?.days,
        );
        return timeTrial;
      }
      return '-';
    },

    numberFormatIdr(money) {
      return new Intl.NumberFormat('id-ID', {
        style: 'currency',
        currency: 'IDR',
      }).format(money);
    },
    numberFormatUsd(money) {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      }).format(money);
    },

    getOfferType(type) {
      if (type != null) {
        return 'General Offer';
      }
      return 'Specified Offer';
    },

    getGeneralOffer(type) {
      const generalOffer = Object.keys(constant.DISCOUNT_PREDEFINED_GROUP).find(
        (key) => constant.DISCOUNT_PREDEFINED_GROUP[key] === type,
      );
      return generalOffer;
    },

    getOfferID(id) {
      this.fetchDiscountsOffersid(id).then((response) => {
        const offers = response.data.data;
        const tempOffers = [];
        offers.map((item) => {
          tempOffers.push(item.offer_id);
        });
        this.offerID = tempOffers;
      });
    },

    getBinCode(id) {
      const binCode = constant.ACTIVE_BIN.find((obj) => obj.value == id);
      return binCode.name;
    },

    getPlatform(id) {
      this.fetchDiscountsPlatformsid(id).then((response) => {
        const platforms = [];
        const listPlatforms = response.data.data;
        listPlatforms.map((item) => {
          platforms.push(item.platform_id);
        });
        const platformsOptions = Object.entries(constant.PLATFORM_PROMO).map(
          (item) => {
            return {
              name: item[0],
              value: item[1],
            };
          },
        );
        const itemPlatform = platformsOptions.filter((item) =>
          [...platforms].includes(item.value),
        );

        this.itemPlatform = itemPlatform.map((val) => val.name).join(', ');
      });
    },

    getPaymentGateway(id) {
      this.fetchDiscountsPaymentsid(id).then((response) => {
        const payments = [];
        const listPayments = response.data.data;
        listPayments.map((item) => {
          payments.push(item.paymentgateway_id);
        });

        const paymentOptions = Object.entries(constant.PAYMENT_GATEWAY).map(
          (item) => {
            return {
              name: item[0],
              value: item[1],
            };
          },
        );
        const itemPayment = paymentOptions.filter((item) =>
          [...payments].includes(item.value),
        );

        this.paymentgateway = itemPayment;
      });
    },

    getDiscountIdr(amount, rule) {
      if (rule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.']) {
        return `${amount} %`;
      }
      return this.numberFormatIdr(amount);
    },
    getDiscountUsd(amount, rule) {
      if (rule == constant.OFFER_DISCOUNT_RULE['Cut by Percentage.']) {
        return `${amount} %`;
      }
      return this.numberFormatUsd(amount);
    },

    alertMsg(msg, type) {
      this.$swal({
        icon: type,
        toast: true,
        position: 'top-end',
        title: msg,
        timer: 10000,
        text: this.message,
      });
    },
  },
};
</script>
