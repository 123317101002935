var render = function () {
  var _vm$item, _vm$item2, _vm$itemCampaign, _vm$item3, _vm$item4, _vm$item5, _vm$item6, _vm$item7, _vm$item8, _vm$item9, _vm$item10, _vm$item11, _vm$item12, _vm$item13, _vm$item14, _vm$item15, _vm$item16, _vm$item17, _vm$item18, _vm$item19, _vm$item20, _vm$item21, _vm$item22, _vm$item23, _vm$item24, _vm$item25, _vm$item26;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-overlay', {
    attrs: {
      "show": _vm.isLoading,
      "rounded": "sm"
    }
  }, [_c('div', {
    staticClass: "row mt-2"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "text-center"
  }, [_c('h1', {
    staticClass: "text-primary"
  }, [_vm._v("Detail Discount")])]), _c('br'), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" General ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount Name")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.name) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Description")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$item2 = _vm.item) === null || _vm$item2 === void 0 ? void 0 : _vm$item2.description) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Campaign")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$itemCampaign = _vm.itemCampaign) === null || _vm$itemCampaign === void 0 ? void 0 : _vm$itemCampaign.name) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Valid From")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$item3 = _vm.item) === null || _vm$item3 === void 0 ? void 0 : _vm$item3.valid_from)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Valid To")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.formatDate((_vm$item4 = _vm.item) === null || _vm$item4 === void 0 ? void 0 : _vm$item4.valid_to)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Sales Recognition")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getSalesRecognition((_vm$item5 = _vm.item) === null || _vm$item5 === void 0 ? void 0 : _vm$item5.sales_recognition)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Partner Participation")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getPartnerParticipant((_vm$item6 = _vm.item) === null || _vm$item6 === void 0 ? void 0 : _vm$item6.partner_participation)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Vendor Participation")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getPartnerParticipant((_vm$item7 = _vm.item) === null || _vm$item7 === void 0 ? void 0 : _vm$item7.partner_participation)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Time Status")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm.formatDate((_vm$item8 = _vm.item) === null || _vm$item8 === void 0 ? void 0 : _vm$item8.valid_to) >= _vm.datenow ? _c('div', {
    staticClass: "badge badge-info"
  }, [_vm._v(" Promo Currently Active ")]) : _vm.formatDate((_vm$item9 = _vm.item) === null || _vm$item9 === void 0 ? void 0 : _vm$item9.valid_to) <= _vm.datenow ? _c('div', {
    staticClass: "badge badge-danger"
  }, [_vm._v(" Promo Already Expired ")]) : _vm._e()])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Status Active")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [(_vm$item10 = _vm.item) !== null && _vm$item10 !== void 0 && _vm$item10.is_active ? _c('div', {
    staticClass: "badge badge-info"
  }, [_vm._v(" active ")]) : _c('div', {
    staticClass: "badge badge-danger"
  }, [_vm._v(" inactive ")])])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Detail Discount ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getDiscountType((_vm$item11 = _vm.item) === null || _vm$item11 === void 0 ? void 0 : _vm$item11.discount_type)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount Rule")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getDiscountRule((_vm$item12 = _vm.item) === null || _vm$item12 === void 0 ? void 0 : _vm$item12.discount_rule)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount Point")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s((_vm$item13 = _vm.item) === null || _vm$item13 === void 0 ? void 0 : _vm$item13.discount_point) + " " + _vm._s(((_vm$item14 = _vm.item) === null || _vm$item14 === void 0 ? void 0 : _vm$item14.discount_point) > 1 ? 'Points' : 'Point') + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount IDR")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getDiscountIdr((_vm$item15 = _vm.item) === null || _vm$item15 === void 0 ? void 0 : _vm$item15.discount_idr, (_vm$item16 = _vm.item) === null || _vm$item16 === void 0 ? void 0 : _vm$item16.discount_rule)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Discount USD")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getDiscountUsd((_vm$item17 = _vm.item) === null || _vm$item17 === void 0 ? void 0 : _vm$item17.discount_usd, (_vm$item18 = _vm.item) === null || _vm$item18 === void 0 ? void 0 : _vm$item18.discount_rule)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Min. USD Order Price")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.numberFormatUsd((_vm$item19 = _vm.item) === null || _vm$item19 === void 0 ? void 0 : _vm$item19.min_usd_order_price)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Max. USD Order Price")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.numberFormatUsd((_vm$item20 = _vm.item) === null || _vm$item20 === void 0 ? void 0 : _vm$item20.max_usd_order_price)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Min. IDR Order Price")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.numberFormatIdr((_vm$item21 = _vm.item) === null || _vm$item21 === void 0 ? void 0 : _vm$item21.min_idr_order_price)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Max. IDR Order Price")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.numberFormatIdr((_vm$item22 = _vm.item) === null || _vm$item22 === void 0 ? void 0 : _vm$item22.max_idr_order_price)) + " ")])]), _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Platform")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.itemPlatform) + " ")])])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Detail BIN Code ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headBinCode,
      "hover": "",
      "responsive": "xl",
      "items": (_vm$item23 = _vm.item) === null || _vm$item23 === void 0 ? void 0 : _vm$item23.bin_codes,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(bincode)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.getBinCode(row.item)) + " ")];
      }
    }])
  })], 1)])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Detail Discount Payment Gateway ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headPaymentGateway,
      "hover": "",
      "responsive": "xl",
      "items": _vm.paymentgateway,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(payment)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.name) + " ")];
      }
    }])
  })], 1)])])])])]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-md-12"
  }, [_c('div', {
    staticClass: "card border-0"
  }, [_c('div', {
    staticClass: "card-header bg-primary"
  }, [_c('h5', [_vm._v(" Detail Discount Offer ")])]), _c('div', {
    staticClass: "card-body"
  }, [_c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("Offer Type")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getOfferType((_vm$item24 = _vm.item) === null || _vm$item24 === void 0 ? void 0 : _vm$item24.predefined_group)) + " ")])]), ((_vm$item25 = _vm.item) === null || _vm$item25 === void 0 ? void 0 : _vm$item25.predefined_group) != null ? _c('div', {
    staticClass: "form-group row"
  }, [_c('label', {
    staticClass: "col-sm-2"
  }, [_vm._v("General Offer")]), _c('label', {
    staticClass: "col-sm-1"
  }, [_vm._v(":")]), _c('div', {
    staticClass: "col-sm-9"
  }, [_vm._v(" " + _vm._s(_vm.getGeneralOffer((_vm$item26 = _vm.item) === null || _vm$item26 === void 0 ? void 0 : _vm$item26.predefined_group)) + " ")])]) : _c('div', [_c('div', {
    staticClass: "form-group row"
  }, [_c('div', {
    staticClass: "col-sm-6"
  }, [_c('b-table', {
    attrs: {
      "id": "table-transition",
      "show-empty": "",
      "fields": _vm.headOfferID,
      "hover": "",
      "responsive": "xl",
      "items": _vm.offerID,
      "sort-icon-left": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.index + 1) + ". ")];
      }
    }, {
      key: "cell(offerID)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item) + " ")];
      }
    }])
  })], 1)])])])])])])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }